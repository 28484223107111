import React, {useRef, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Services from './Services';
import Aboutus from './Aboutus';
import Testimonials from './Testimonials';
import Products from './Products';
import Whychooseus from './Whychooseus';
import slide1 from '../assets/images/l1.jpeg';
import slide2 from '../assets/images/bg-2.png';

const Hero = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 3000,  // Set autoplay speed in milliseconds
  };
  
  useEffect(() => {
    // Start the slider autoplay when the component mounts
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  }, []); // Empty dependency array ensures this effect runs only once after the initial render


  const prevSlide = () => {
    slider.slickPrev();
  };

  const nextSlide = () => {
    slider.slickNext();
  };

  let slider;

  return (
    <section className="laundry-section">
      <section className="hero-section">
        <div className=" w-full text-center pt-1 md:pt-20 md:pb-10 slider-bg">
          <div className="w-full max-w-7xl relative mx-auto slider-container mx-auto w-full">
            <Slider ref={c => (slider = c)} {...settings} className='w-full' >
              <div>
                <div className='w-full sm:flex place-content-start items-start p-3'>
                  <div className="slide-text w-full sm:w-1/2 pt-5 md:pr-11 text-start">
                    <h2 className='text-4xl md:text-7xl	text-start font-semibold py-5 deca'>
                      Eco-Friendly <span className='text-[#44d6fe]'>Dry Cleaning</span> Services</h2>
                    <p className='text-lg	font-sans	text-black text-start font-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum magnam, voluptates distinctio, officia architecto</p>
                    <button class="rounded-3xl bg-[#0346a7] border-2 border-lime-200 h-11	px-7 my-5 text-white	">Read More</button>
                  </div>
                  <div className='image w-full sm:w-1/2 place-content-end	flex'>
                    <img src={slide2} alt="Slide 1" className="slide-image h-full w-full object-cover
                     h-96 rounded-xl"/>
                  </div>
                </div>
              </div>
              <div>
                <div className='w-full sm:flex place-content-start	items-start p-3'>
                  <div className="slide-text w-full sm:w-1/2  pt-5 md:pr-11 text-start">
                    <h2 className='text-4xl md:text-7xl	text-start font-semibold py-5 deca'>
                      Wonderful <span className='text-[#44d6fe]'>Laundry</span> Done the Right Way</h2>
                    <p className='text-lg	font-sans	text-black text-start font-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum magnam, voluptates distinctio, officia architecto </p>
                    <button class="rounded-3xl	bg-[#0346a7] border-2 border-lime-200	h-11	px-7 my-5 text-white	">Read More</button>
                  </div>
                  <div className='image w-full sm:w-1/2  place-content-end	flex'>
                    <img src={slide1} alt="Slide 1" className="slide-image w-full h-full object-cover
                     h-96 rounded-3xl	" />
                  </div>
                </div>
              </div>
              {/* Add more slides as needed */}
            </Slider>
            <div className="custom-navigation flex align-item-start">
              <button className="prev mx-2	my-8 p-2 bg-[#44d6fe]" onClick={prevSlide}><svg
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path className='text-white' stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
              </svg>
              </button>
              <button className="next mx-2	my-8 p-2 bg-[#44d6fe]" onClick={nextSlide}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path className='text-white' stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="services-section ">
        <Services />
      </div>
      <div className="services-section bg-[#f8feff]" >
        <Products/>
      </div>
      <div className="about-section">
        <Aboutus />
      </div>
      <div className="whychooseus-section  bg-[#f8feff]">
        <Whychooseus />
      </div>
      <div className="testimonials-section ">
        <Testimonials />
      </div>
    </section>
  );
}

export default Hero;
