import React from 'react';
import about from '../assets/images/aboutUs.jpg';

const Aboutus = () => {
  return (
    <section id="about-us" className='w-full max-w-7xl relative mx-auto py-6 px-2 md:py-20' >
      <div className="flex flex-col-reverse	sm:flex-row	place-content-center	items-center">
        <img src={about} alt="Slide 1" className="mx-auto mb-8 rounded-lg w-ull sm:w-1/2 h-96	object-cover	" />
        <div className='text w-ull sm:w-1/2	 sm:pl-10'>
          <h2 className="text-3xl font-semibold mb-12 deca text-5xl font-semibow-1/2ld ">About <span className='text-[#44d6fe]'>
          Us </span></h2>
          <p className="deca px-2 font-semibold text-gray-700 text-xl border-l-4 border-cyan-300	">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt modo consequat.
          </p>
          <p className="deca py-6 font-light text-gray-700 text-lg font-sans	text-black">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;

