import React from 'react';
import '../../assets/css/Services.css'; // Import CSS file for styling
import services1 from '../../assets/images/services-01.svg';
import services2 from '../../assets/images/services-02.svg';
import services3 from '../../assets/images/services-03.svg';


const ServicesPage = () => {
    return (
        <section>
            <div className='top-bar relative mb-10 flex mx-auto text-center place-content-center items-center
        h-52 md:h-80'>
                {/* <img src={bg} alt="Slide 1" className="mx-auto mb-8 rounded-lg w-full h-[24rem] object-cover	" /> */}
                <div className='w-full max-w-7xl relative mx-auto '>
                    <h1 className='text-4xl	md:text-6xl font-semibold deca'>Our <span className='text-[#44d6fe]'>Services</span></h1>
                </div>
            </div>
            <section id="services" className="w-full max-w-7xl relative m-auto mx-auto
         py-14 px-2 md:py-14">
                <div className="text-center">
                    {/* <h2 className="text-3xl font-semibold mb-8 deca text-5xl font-semibold">Our <span className='text-[#44d6fe]'>Services</span></h2> */}
                    <div className="sm:flex justify-center sm:space-x-8 items-start">
                        {/* Service Cards */}
                        <div className="service-card text-start h-auto rounded-lg shadow p-5 w-full">
                            <div className='icon relative'>
                                <img src={services1} alt="Slide 1" className="object-cover w-16 my-4 relative" />
                            </div>
                            <h1 className='text-xl font-bold py-1 deca '>Custom Servicing Model</h1>
                            <p className='deca py-3 font-light'> A custom servicing model provides tailored solutions to address individual client needs with precision and effectiveness.
                            </p>
                        </div>

                        <div className="service-card text-start mt-5 sm:mt-10 rounded-lg shadow p-5	 w-full">
                            <div className='icon relative'>
                                <img src={services2} alt="Slide 1" className="object-cover w-16	my-4 relative" />
                            </div>
                            <h1 className='text-xl font-bold py-1 deca'>All Hotel Linen</h1>
                            <p className='deca py-3 font-light text-base'>our service ensures that every fabric, receives meticulous care, maintaining impeccable cleanliness and freshness to exceed guest expectations.
                            </p>
                        </div>

                        <div className="service-card text-start mt-5 sm:mt-16 rounded-lg shadow p-5	 w-full">
                            <div className='icon relative'>
                                <img src={services3} alt="Slide 1" className="object-cover w-16	my-4 relative" />
                            </div>
                            <h1 className='text-xl font-bold py-1 deca'>Pick up and Delivery</h1>
                            <p className='deca py-3 font-light'>Our service ensures convenience and efficiency, saving guests time and effort by collecting and delivering hotel linens directly to their doorstep.</p>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}
export default ServicesPage;
