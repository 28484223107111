import React from 'react';
import { Link } from 'react-router-dom';
// import logo from '../assets/images/logo.png';
const Footer = () => {
  return (
    <footer className="bg-[#15b1db] text-white py-6 text-center">
      <div className='sm:flex place-content-center	items-start text-start pt-5'>
        <div className='footer1 my-5 px-5 xl:px-16'>
          <Link to="/"><p className="text-4xl deca font-blod leading-7">One-Hotel </p>
          </Link>
        </div>
        <div className='footer1 my-5 border-x  px-5  xl:px-16'>
          <p className="text-sm deca font-extralight	leading-6">Contact us:<br />
            123-456-7890 </p>
        </div>
        <div className='footer1 my-5 px-5 xl:px-16'>
          <p className="text-sm deca font-extralight leading-6">
            Email id:<br />
            info@commerciallaundry.com</p>
        </div>
      </div>
      <p className="text-sm deca font-extralight pt-6">© 2024 All Rights Reserved by OneHotel Group</p>
    </footer>
  );
}
export default Footer;
