import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/TestimonialCarousel.css'; // Custom CSS for testimonial carousel
import quote from '../assets/images/quotes.png';
import icon1 from '../assets/images/shape-1.png';
import icon2 from '../assets/images/shape-2.png';

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2, // Display all cards in one slide
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Adjust settings for tablets
        settings: {
          slidesToShow: 1, // Display 1 card on tablets
        },
      },
    ],
  };
  const prevSlide = () => {
    slider.slickPrev();
  };
  const nextSlide = () => {
    slider.slickNext();
  };
  let slider;

  return (
    <section className='relative'>
      
    <div className="mx-auto text-center relative w-full max-w-7xl  mx-auto pt-6 pb-28 px-2 md:pt-24 md:pb-36">
    <div className='relative'>
       <img src={icon1} alt="quote" className='shape absolute left-0 top-0 hidden md:block opacity-20	' />
        <img src={icon2} alt="quote" className='shape1 absolute right-0 top-0 hidden md:block opacity-20	' />
       </div>
      <h2 className="text-3xl font-semibold mb-1 deca text-5xl font-semibow-1/2ld ">Our <span className='text-[#44d6fe]'>
        Testimonials</span></h2>

      <div className="mx-auto text-center relative w-full max-w-7xl  mx-auto px-2 md:pt-18 ">
        <Slider ref={c => (slider = c)} {...settings}>
          <div className="testimonial-slide mt-12">
            <div className='flex my-5 place-content-center items-center  py-5 md:pl-6 pr-0'>
              <div className=" card testimonial-item border shadow rounded-3xl p-7 bg-white">
                <div className='bg-[#44d6fe] w-fit	rounded-full'>
                  <img src={quote} alt="quote" className='w-16 h-16 m-l-5' />
                </div>
                <p className='deca py-3 font-light text-lg'>I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                <h3 className='text-xl font-semibold pt-6 pb-2 deca text-[#438ebb]'>Sam D.</h3>
              </div>
            </div>
          </div>

          <div className="testimonial-slide mt-12">
            <div className='flex my-5 place-content-center items-center  py-5 md:pl-6 pr-0'>
              <div className=" card testimonial-item border shadow rounded-3xl p-7 bg-white">
                <div className='bg-[#44d6fe] w-fit	rounded-full'>
                  <img src={quote} alt="quote" className='w-16 h-16 m-l-5' />
                </div>
                <p className='deca py-3 font-light text-lg'>I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                <h3 className='text-xl font-semibold pt-6 pb-2 deca text-[#438ebb]'>Sam D.</h3>
              </div>
            </div>
          </div>

          <div className="testimonial-slide mt-12">
            <div className='flex my-5 place-content-center items-center  py-5 md:pl-6 pr-0'>
              <div className=" card testimonial-item border shadow rounded-3xl p-7 bg-white">
                <div className='bg-[#44d6fe] w-fit	rounded-full'>
                  <img src={quote} alt="quote" className='w-16 h-16 m-l-5' />
                </div>
                <p className='deca py-3 font-light text-lg'>I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                <h3 className='text-xl font-semibold pt-6 pb-2 deca text-[#438ebb]'>Sam D.</h3>
              </div>
            </div>
          </div>

          <div className="testimonial-slide mt-12">
            <div className='flex my-5 place-content-center items-center  py-5 md:pl-6 pr-0'>
              <div className=" card testimonial-item border shadow rounded-3xl p-7 bg-white">
                <div className='bg-[#44d6fe] w-fit	rounded-full'>
                  <img src={quote} alt="quote" className='w-16 h-16 m-l-5' />
                </div>
                <p className='deca py-3 font-light text-lg'>I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                <h3 className='text-xl font-semibold pt-6 pb-2 deca text-[#438ebb]'>Sam D.</h3>
              </div>
            </div>
          </div>

          <div className="testimonial-slide mt-12">
            <div className='flex my-5 place-content-center items-center  py-5 md:pl-6 pr-0'>
              <div className=" card testimonial-item border shadow rounded-3xl p-7 bg-white">
                <div className='bg-[#44d6fe] w-fit	rounded-full'>
                  <img src={quote} alt="quote" className='w-16 h-16 m-l-5' />
                </div>
                <p className='deca py-3 font-light text-lg'>I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                <h3 className='text-xl font-semibold pt-6 pb-2 deca text-[#438ebb]'>Sam D.</h3>
              </div>
            </div>
          </div>


        </Slider>
        <div className="custom-navigation flex align-item-start ">
          <button className="prev absolute right-14 bg-[#44d6fe] py-3 px-4"
            onClick={prevSlide}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 41"
              className='w-2 md:w-3 fill-[#000]'><path d="M20.3 40.8 0 20.5 20.3.2l.7.7L1.3 20.5 21 40.1z"></path></svg>
          </button>
          <button className="next absolute right-0 bg-[#44d6fe]  py-3 px-4" onClick={nextSlide}>
            <svg xmlns="http://www.w3.org/2000/svg " className='w-2 md:w-3 rotate-180 fill-[#000]	' viewBox="0 0 21 41"><path d="M20.3 40.8 0 20.5 20.3.2l.7.7L1.3 20.5 21 40.1z"></path></svg>
          </button>
        </div>
      </div>
    </div>
    </section>

  );
}

export default Testimonial;
