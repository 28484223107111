import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Aboutpage from './components/inner-pages/About-page';
import ServicePage from './components/inner-pages/services-page';
import ProductsPage from './components/inner-pages/products-page';
import TestimonialPage from './components/inner-pages/Testimonial-page';
import WhyChooseUsPage from './components/inner-pages/Whychooseus-page';

import './App.css';
function App() {
  return (
    <Router>
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/About-us" element={<Aboutpage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/testimonials" element={<TestimonialPage />} />
          <Route path="/why-choose-us" element={<WhyChooseUsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
