import React from 'react';
import '../assets/css/Services.css'; // Import CSS file for styling
import product1 from '../assets/images/product-01.svg';
import product2 from '../assets/images/product-02.svg';
import product3 from '../assets/images/product-03.svg';


const Products = () => {
    return (
        <section id="services" className="w-full max-w-7xl relative m-auto mx-auto
         py-14 px-2 md:py-24">
            <div className="text-center">
                <h2 className="text-3xl font-semibold mb-8 md:mb-16 deca text-5xl font-semibold">Our <span className='text-[#44d6fe]'>Products</span></h2>
                <div className="sm:flex justify-center sm:space-x-8 items-start ">
                    {/* Service Cards */}
                    <div className="service-card p-card text-center h-auto rounded-lg shadow mb-4 px-5 py-7 w-full relative bg-white">
                        <div className='icon relative'>
                            <img src={product1} alt="Slide 1" className="object-cover w-16 my-4 relative m-auto" />
                        </div>
                        <h1 className='text-xl font-bold py-1 deca '>Bathroom Amenities/ Toiletries</h1>
                        <p className='deca py-3 font-light'>Experience the epitome of cleanliness and freshness with our meticulously crafted laundry products, designed to elevate your bathroom amenities and toiletries to new heights of luxury and comfort.
                        </p>
                    </div>

                    <div className="service-card p-card text-center h-auto rounded-lg shadow mb-4 px-5 py-7 w-full relative">
                        <div className='icon relative'>
                            <img src={product2} alt="Slide 1" className="object-cover w-16	my-4 relative m-auto" />
                        </div>
                        <h1 className='text-xl font-bold py-1 deca'>Cleaning supplies</h1>
                        <p className='deca py-3 font-light text-base'>Experience the power of cleanliness with our comprehensive range of top-quality cleaning supplies, meticulously crafted to tackle dirt and grime while leaving your space refreshed and immaculate.
                        </p>
                    </div>

                    <div className="service-card p-card text-center h-auto rounded-lg shadow mb-4 px-5 py-7 w-full relative">
                        <div className='icon relative'>
                            <img src={product3} alt="Slide 1" className="object-cover w-16	my-4 relative m-auto" />
                        </div>
                        <h1 className='text-xl font-bold py-1 deca'>Sheets,Towels & pillow cases</h1>
                        <p className='deca py-3 font-light'>Wrap yourself in luxury and comfort with our premium selection of sheets, towels, and pillow cases, designed to elevate your relaxation experience to new levels of indulgence comfort and style.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Products;
