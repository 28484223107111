import React from 'react';
import '../assets/css/Whychooseus.css'; // Import CSS file for styling
import whyus1 from '../assets/images/why-us-1.svg';
import whyus2 from '../assets/images/why-us-2.svg';
import whyus3 from '../assets/images/why-us-3.svg';
const Whychooseus = () => {
  return (
    <section id="why-choose-us" className=' w-full max-w-7xl relative mx-auto py-6 px-2 md:pt-20 md:pb-12'>
      <div className="mx-auto text-center">
        <h2 className="text-3xl font-semibold mb-7 md:mb-12 deca text-5xl font-semibow-1/2ld ">Why
          <span className='text-[#44d6fe]'>
            Choose Us</span></h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 py-5">
          <div className="why-choose-card p-1 md:p-5">
            <img src={whyus1} alt="Slide 1" className="object-cover w-16 m-auto relative " />
            <h3 className="text-xl font-semibold mt-8 mb-3 deca">Automated Perfection</h3>
            <p className="text-gray-700 deca">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <b className='text-6xl text-zinc-200 py-6 block deca'>01.</b>
          </div>
          <div className="why-choose-card p-1 md:p-5">
            <img src={whyus2} alt="Slide 1" className="object-cover w-16 m-auto relative" />
            <h3 className="text-xl font-semibold mt-8 mb-3 deca">Area of Operations</h3>
            <p className="text-gray-700 deca">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <b className='text-6xl text-zinc-200 py-6 block deca '>02.</b>

          </div>
          <div className="why-choose-card p-1 md:p-5">
            <img src={whyus3} alt="Slide 1" className="object-cover w-16 m-auto relative" />
            <h3 className="text-xl font-semibold  mt-8 mb-3 deca">Unmatched Quality</h3>
            <p className="text-gray-70 deca">Ut enim ad minim veniam, quis nostrud commodo consequat.</p>
            <b className='text-6xl text-zinc-200 py-6 block deca'>03.</b>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whychooseus;
